// returns true or false based on current Bootstrap breakpoint
function isBreakpoint(alias) {
  return $('.device-' + alias).is(':visible');
}

// returns current breakpoint as string based on isBreakpoint
function getBreakpoint() {
  if(isBreakpoint('lg')) { return 'lg'; }
  if(isBreakpoint('md')) { return 'md'; }
  if(isBreakpoint('sm')) { return 'sm'; }
  return 'xs';
}

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Mega Menu Navigation
        // Duplicate the secondary_navigation into each manu item that has a sub-menu
        var $mainMenu = $("#menu-main-menu");

        // Move the image
        $mainMenu.find("li.has-image > .menu-image").each(function() {
          $(this).appendTo( $(this).siblings(".sub-menu-container") );
          $(this).addClass("show-image");
        });

        // Wrap sub-menu links in div container
        $mainMenu.find("li.menu-item-has-children").each(function() {
          $(this).find("li").not(".special-link").wrapAll("<div class='main-links'></div>");
          $(this).find("li.special-link").wrapAll("<div class='special-links'></div>");

          // Tablet/mobile if sub-menu create clickable button
          $(this).prepend("<div class='sub-nav-menu'></div>");
        });

        // Tablet/mobile hamburger menu
        $(".menu-toggle").click (function() {
          $(".menu-mobile").toggleClass("menu-off");
          $(".menu-toggle").toggleClass("button-move");
          $("body, .body-wrap").toggleClass("menu-active");
        });

        $(".sub-nav-menu").click(function(e) {
          e.preventDefault();
          $(this).siblings(".sub-menu-container").toggleClass("menu-active");
          $(this).toggleClass("menu-active");
        });

        // Move the Utility navigation based on mobile/tablet or desktop
        function moveUtilityNav() {
          if($(window).width() <= 1199) {
            $("nav.nav-utility").insertAfter("nav.nav-main");
          } else {
            $("nav.nav-utility").prependTo(".utility-nav-row .container");
          }
        }
        moveUtilityNav();

        // Mobile icon dropdowns
        $(".mobile-locations, .mobile-phone").click(function() {
          $(".mobile-icon").not(this).find("ul").removeClass("js-active");
          $(this).find("ul").toggleClass("js-active");
        });

        // On window resize...
        $(window).resize(function() {
          moveUtilityNav();
        });


        // Others Saved Carousel
        if($(".others-saved-carousel").length) {
          $(".others-saved-carousel").slick({
            nextArrow: "<i class='fa fa-arrow-right'>></i>",
            prevArrow: "<i class='fa fa-arrow-left'><</i>",
            dots: true,
            infinite: true,
            slidesToShow: 5,

            responsive: [{
              breakpoint: 1199,
              settings: {
                slidesToShow: 3,
              }
            }, {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
              }
            }, {
              breakpoint: 762,
              settings: {
                arrows: false,
                slidesToShow: 1,
              }
              //settings: "unslick" // destroys slick
            }]

          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        // Home Banner Carousel
        $('.home-carousel').slick({
          nextArrow: "<i class='fa fa-arrow-right'>></i>",
          prevArrow: "<i class='fa fa-arrow-left'><</i>",
          dots: false,
          infinite: true,
          slidesToShow: 1,
          adaptiveHeight: true
       });

       // Move the buttons in the carousel for mobile/desktop
       function moveHomeBtn() {
         $(".slide-image.slick-slide").each(function() {
           var buttons = $(this).find(".btn");

           if($(window).width() <= 763) {
             buttons.appendTo( $(this).find(".slide-details") );
           } else {
             buttons.insertBefore( $(this).find(".secondary-text" ) );
           }
         });
       }
       moveHomeBtn();

       // On window resize...
       $(window).resize(function() {
         moveHomeBtn();
       });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Featured Products page, note the change from featured-products to featured_products.
    'featured_products': {
      init: function() {
        // JavaScript to be fired on the featured products page
        var $container = $('#isotope-list'); // The ID for the list with all the blog posts
        $container.isotope({ // Isotope options, 'item' matches the class in the PHP
          itemSelector : '.item'
        });

        // Add the class selected to the item that is clicked, and remove from the others
        var $optionSets = $('#filters'),
            $optionLinks = $optionSets.find('a');

        $optionLinks.click(function(){
          var $this = $(this);
          // Don't proceed if already selected
          if ( $this.hasClass('selected') ) {
            return false;
          }
          var $optionSet = $this.parents('#filters');
              $optionSets.find('.selected').removeClass('selected');
          $this.addClass('selected');

          // When an item is clicked, sort the items.
          var selector = $(this).attr('data-filter');
          $container.isotope({ filter: selector });

          return false;
        });

        $(".furniture-details .saved-amount").each(function() {
          $(this).insertAfter($(this).parent().find('.manufacturer'));
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
